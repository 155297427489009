import { APICore } from "./apiCore";

const api = new APICore();

export function getSubscriptions() {
  const endpoint = "/subscriptions/user/subscriptions/";
  return api.get(`${endpoint}`, { page_size: 0 });
}

export function getAllProducts() {
  const endpoint = "/subscriptions/user/products/";
  return api.get(`${endpoint}`, { page_size: 0 });
}

export function createStripeSession(data: any) {
  const endpoint = `/subscriptions/user/create-checkout-session/`;
  return api.create(endpoint, data);
}

export function getSubscriptionHistory(subscriptionId: number) {
  const endpoint = `subscriptions/user/subscriptions/${subscriptionId}/payments/`;
  return api.get(`${endpoint}`);
}

export function getSubscriptionLists() {
  const endpoint = "/users/payments/";
  return api.get(`${endpoint}`, {});
}

export function createSubscriptionFlow(data: any) {
  console.log("data", data);

  const endpoint = `/users/payments/`;
  return api.create(endpoint, {
    transaction_type: data,
    payment_method: "STRIPE",
  });
}
