import { APICore } from "./apiCore";

const api = new APICore();

export function getNotifications() {
  const endpoint = "/users/notifications/";
  return api.get(`${endpoint}`);
}

export function updateNotification(notification: any) {
  const endpoint = `/users/notifications/${notification.id}/`;
  return api.updatePatch(endpoint, { is_read: true });
}
