import PageTitle from "../../../components/Layout/PageTitle";
import { useMutation, useQuery } from "react-query";
import {
  createSubscriptionFlow,
  getSubscriptionLists,
} from "../../../helpers/api/subscription";
import { toast } from "react-toastify";
import { Button, Row, Col, Table } from "react-bootstrap";
import Spinner from "../../../components/Layout/Spinner";
import { AxiosResponse } from "axios";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { USERS_TYPE } from "../../../constants/userTypes";

interface SubscriptionPayload {
  id: string;
  object: string;
  api_version: string;
  created: number;
  data: {
    object: {
      id: string;
      object: string;
      amount_total: number;
      currency: string;
      customer_details: {
        email: string;
        name: string;
        address: {
          country: string;
        };
      };
      payment_status: string;
      status: string;
    };
  };
}

interface Subscription {
  id: string;
  transaction_id: string;
  amount: number;
  transaction_type: string;
  status: string;
  payload: SubscriptionPayload | null;
}

interface SubscriptionResponse {
  data: {
    data: Subscription[];
  };
}

const Subscribe = () => {
  const { user } = useSelector((state: RootState) => ({
    user: state.Auth.user,
  }));

  const { data: subscriptionsHistory, isLoading } = useQuery<
    SubscriptionResponse,
    Error
  >({
    queryKey: ["products"],
    queryFn: () => getSubscriptionLists(),
    staleTime: Infinity,
  });

  const { mutate: initiatePayment, isLoading: paymentLoading } = useMutation<
    AxiosResponse,
    Error,
    string
  >({
    mutationFn: createSubscriptionFlow,
    onSuccess: (data) => {
      if (data.statusText === "OK") {
        window.location.href = data.data.data.redirect_url;
      } else {
        toast.error("Failed to initiate payment");
      }
    },
    onError: () => {
      toast.error("Failed to initiate payment");
    },
  });

  const handleBuySubscription = () => {
    if (user) {
      if (user.user_type === USERS_TYPE.PROPERTY_USER) {
        initiatePayment("ADD_EVENT");
      }

      if (user.user_type === USERS_TYPE.FOODTRUCK_USER) {
        initiatePayment("BOOKING");
      }
    } else {
      toast.error("Failed to initiate payment");
    }
  };

  const hasActiveSubscription = subscriptionsHistory?.data?.data?.some(
    (sub: Subscription) => sub.status === "SUCCEEDED"
  );

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          {
            label: "Subscriptions",
            path: "/apps/subscription/subscribe",
            active: true,
          },
        ]}
        title={"Subscriptions"}
      />

      <>
        {isLoading && (
          <div
            style={{
              display: "flex",
              width: "100%",
              padding: "60px 0",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "300px",
            }}
          >
            <Spinner />
          </div>
        )}
        {!isLoading && !hasActiveSubscription && (
          <Row
            style={{
              justifyContent: "center",
              marginTop: "20px",
              padding: "20px",
            }}
          >
            <Col
              xs={12}
              md={6}
              style={{ textAlign: "center", padding: "20px" }}
            >
              <h4 style={{ color: "White", marginBottom: "15px" }}>
                No Active Subscriptions
              </h4>
              <p style={{ color: "white", marginBottom: "20px" }}>
                Purchase a subscription to start booking events
              </p>
              <Button
                variant="primary"
                onClick={handleBuySubscription}
                disabled={paymentLoading}
                style={{
                  padding: "10px 20px",
                  fontSize: "16px",
                  borderRadius: "5px",
                }}
              >
                {paymentLoading ? "Processing..." : "Buy Subscription"}
              </Button>
            </Col>
          </Row>
        )}
        {!isLoading && hasActiveSubscription && (
          <Row style={{ marginTop: "20px" }}>
            <Col xs={12}>
              <div
                style={{
                  // background: "#fff",
                  borderRadius: "8px",
                  boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                  padding: "20px",
                }}
              >
                <h4
                  style={{
                    marginBottom: "20px",
                    color: "white",
                  }}
                >
                  Payment History
                </h4>
                <div style={{ overflowX: "auto" }}>
                  <Table
                    striped
                    bordered
                    hover
                    responsive
                    style={{ minWidth: "600px" }}
                  >
                    <thead>
                      <tr>
                        <th style={{ color: "white", fontWeight: "600" }}>
                          Transaction ID
                        </th>
                        <th style={{ color: "white", fontWeight: "600" }}>
                          Amount
                        </th>
                        <th style={{ color: "white", fontWeight: "600" }}>
                          Status
                        </th>
                        <th style={{ color: "white", fontWeight: "600" }}>
                          Purchase Date
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {subscriptionsHistory?.data?.data
                        ?.filter(
                          (sub: Subscription) => sub.status === "SUCCEEDED"
                        )
                        .map((sub: Subscription) => (
                          <tr key={sub.id}>
                            <td style={{ color: "white" }}>
                              {sub.transaction_id}
                            </td>
                            <td style={{ color: "white" }}>${sub.amount}</td>
                            <td>
                              <span
                                style={{
                                  color: "#28a745",
                                  fontWeight: "500",
                                  textTransform: "uppercase",
                                }}
                              >
                                {sub.status}
                              </span>
                            </td>
                            <td style={{ color: "white" }}>
                              {new Date(
                                (sub.payload?.created ?? 0) * 1000
                              ).toLocaleString()}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </>
    </>
  );
};

export default Subscribe;
