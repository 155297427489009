import { UserRegisterType } from "../../pages/auth/Register";
import { APICore } from "./apiCore";

const api = new APICore();

// account
function login(params: { username: string; password: string }) {
  const endpoint = "/auth/login/";
  return api.create(`${endpoint}`, {
    ...params,
    role: "user",
  });
}

function getProfile() {
  const endpoint = "/users/profile/";
  return api.get(`${endpoint}`);
}

function logout() {
  const endpoint = "/logout/";
  return api.create(`${endpoint}`, {});
}

function signup(params: UserRegisterType) {
  // const endpoint = "/accounts/public/register/";
  const endpoint = "/auth/register/";
  return api.create(`${endpoint}`, params);
}

function forgotPassword(params: { username: string }) {
  const endpoint = "/forget-password/";
  return api.create(`${endpoint}`, params);
}

export { login, logout, signup, forgotPassword, getProfile };
