import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  Image,
  ListGroup,
  Modal,
  Spinner,
} from "react-bootstrap";
import PageTitle from "../../../../components/Layout/PageTitle";

import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getFoodTruckDetailsPublic } from "../../../../helpers/api/foodTruck";

const FoodTruckDetailsPublic = () => {
  let params = useParams();
  const { data: foodTruckDetails, isLoading } = useQuery({
    queryKey: ["foodTruckDetails", params],
    queryFn: () => getFoodTruckDetailsPublic(params),
  });
  console.log("foodTruckDetails", foodTruckDetails);

  const [modalShow, setModalShow] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const handleImageClick = (image: string) => {
    setSelectedImage(image);
    setModalShow(true);
  };

  if (isLoading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "300px" }}
      >
        <Spinner animation="border" variant="primary" />
        <span className="ms-2">Fetching food trucks details...</span>
      </div>
    );
  }

  return (
    <React.Fragment>
      <PageTitle
        breadCrumbItems={[
          { label: "Food Trucks", path: "/apps/foodtrucks" },
          {
            label: "Food Truck Details",
            path: "/apps/foodtrucks/detail",
            active: true,
          },
        ]}
        title={"Food Truck Details"}
      />

      <Row>
        <Col xl={9} lg={9}>
          <Card className="d-block">
            <Card.Body>
              <h3 className="mt-0 font-20">
                {foodTruckDetails?.data.data.name}
              </h3>
              <div className={"badge bg-success text-light mb-3"}>
                {foodTruckDetails?.data.data.service_type}
              </div>
              <div>
                {foodTruckDetails?.data.data.current_menu && (
                  <Image
                    style={{ width: 200, cursor: "pointer" }}
                    src={foodTruckDetails?.data.data.current_menu}
                    alt={foodTruckDetails?.data.data.name}
                    className="img-fluid rounded mb-3"
                    onClick={() =>
                      handleImageClick(foodTruckDetails?.data.data.current_menu)
                    }
                  />
                )}
              </div>
              <div>
                <p className="text-muted">
                  <i className="mdi mdi-map-marker"></i> Address:{" "}
                  {foodTruckDetails?.data.data.operating_city},{" "}
                  {foodTruckDetails?.data.data.operating_state}
                </p>
              </div>
              <h4>Cuisine Overview:</h4>
              <p className="text-muted mb-2">
                {foodTruckDetails?.data.data.description}
              </p>
            </Card.Body>
          </Card>

          <Card className="d-block mt-3">
            <Card.Body>
              <h4>Menu Items</h4>
              <ListGroup>
                {foodTruckDetails?.data?.data?.items?.map((item: any) => (
                  <ListGroup.Item key={item.id}>
                    <Row>
                      <Col md={2}>
                        {item.image && (
                          <Image
                            src={item.image}
                            alt={item.name}
                            className="img-fluid rounded"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleImageClick(item.image)}
                          />
                        )}
                      </Col>
                      <Col md={8}>
                        <h5 className="mb-1">{item.name}</h5>
                        <p className="mb-1">{item.description}</p>
                      </Col>
                      <Col md={2} className="text-end">
                        <p className="text-muted mb-0">Price: ${item.price}</p>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={3} lg={3}>
          <Card>
            <Card.Body>
              <h4>Contact:</h4>
              <p className="mb-0">
                Phone: {foodTruckDetails?.data.data.contact_phone}
              </p>
              <p className="mb-0">
                Email: {foodTruckDetails?.data.data.contact_email}
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        centered
      >
        <Modal.Body className="p-0">
          <Image
            src={selectedImage}
            alt="Selected"
            className="img-fluid w-100 h-100"
            style={{ objectFit: "contain" }}
          />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default FoodTruckDetailsPublic;
