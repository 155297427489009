import { APICore } from "./apiCore";

const api = new APICore();

export function getEvents() {
  const endpoint = "/users/locations/all/events/";
  return api.get(`${endpoint}`, {});
}

export function getEventDetails(params: any) {
  const endpoint = `/properties/user/events/${params.id}/`;
  return api.get(`${endpoint}`, {});
}

export function createEvent(params: any) {
  const endpoint = "/users/locations/events/";
  return api.create(`${endpoint}`, params);
}

export function bookEvent(params: any) {
  const endpoint = "/users/bookings/";
  return api.create(`${endpoint}`, params);
}
export function cancelEvent(id: string | number | undefined) {
  const endpoint = `/users/bookings/cancel-bookings/${id}/`;
  return api.create(`${endpoint}`, {});
}

export function getAllBookings() {
  const endpoint = "/users/bookings/";
  return api.get(`${endpoint}`, { status: "SUCCEEDED" });
}

export function getBookingDetails(params: {
  id: number | string | null;
  date: string;
}) {
  if (!params.id) return null;
  const endpoint = `/users/bookings/event-bookings/${params.id}/`;
  return api.get(`${endpoint}`, { date: params.date + " 00:00:00+00:00" }); // have to send time also
}

export function updateEvent(params: any) {
  const endpoint = `/users/locations/${params.location_id}/events/${params.id}/`;

  return api.updatePatch(`${endpoint}`, params);
}

export function cancelBooking(id: number): any {
  if (!id) return null;
  const endpoint = `/users/bookings/cancel-bookings/${id}/`;
  return api.create(`${endpoint}`, {});
}

export function getPropertyBookings() {
  const endpoint = "/users/locations/all/events/";
  return api.get(`${endpoint}`, {});
}
