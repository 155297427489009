import { APICore } from "./apiCore";

const api = new APICore();

export function getLocations() {
  const endpoint = "/users/locations/";
  return api.get(`${endpoint}`, {});
}

export function getAllLocations(data: any) {
  const endpoint = "/public/locations/";

  const filteredData: any = {};

  if (data.property_type) {
    filteredData.property_type = data.property_type;
  }
  if (data.state) {
    filteredData.state = data.state;
  }
  if (data.city) {
    filteredData.city = data.city;
  }
  if (data.search) {
    filteredData.search = data.search;
  }

  return api.get(`${endpoint}`, filteredData);
}

export function getLocationDetails(params: any) {
  const endpoint = `/users/locations/${params.id}/`;
  return api.get(`${endpoint}`, {});
}

export function getLocationEventsDetails(params: any) {
  const endpoint = `/users/locations/${params.location}/events/${params.id}`;
  return api.get(`${endpoint}`, {});
}

export function getLocationDetailsPublic(params: any) {
  const endpoint = `/public/locations/${params.id}/`;
  return api.get(`${endpoint}`, {});
}

export function createLocation(params: any) {
  const endpoint = "/users/locations/";
  return api.create(`${endpoint}`, params);
}

export function updateLocation(params: any) {
  const endpoint = `/users/locations/${params.id}`;
  return api.updatePatch(`${endpoint}`, params);
}

export async function getOperationCity(stateId: any) {
  if (!stateId) {
    return { data: { data: [] } };
  }
  const endpoint = `users/settings/states/${stateId}/cities/`;
  return api.get(endpoint, {});
}

export function getOperationState() {
  const endpoint = `configurations/public/locations/states/`;
  return api.get(`${endpoint}`, {});
}

export function getStateOption(id: number) {
  const endpoint = `/configurations/user/states/${id}/cities/`;
  return api.get(`${endpoint}`, {});
}

export function getOperationCityDashboard() {
  const endpoint = `/public/settings/cities/`;
  return api.get(`${endpoint}`, {});
}

export function getOperationStateDashboard() {
  const endpoint = `users/settings/states/`;
  return api.get(`${endpoint}`, {});
}
